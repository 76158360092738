  
 
// import ERC721 from '../../src/Abi/erc721.json'
// import ERC1155 from '../../src/Abi/erc1155.json'
// import TRADE from '../../src/Abi/market.json'

var EnvName = 'production';

var key = {};
key.KEY = 'CardBo@rD1290%6Fine3'
key.ONEDAYINSECONDS = 0
key.BLOCKS_PER_YEAR = 0
key.RPAD_ADDRESS = ''
key.ROUTER = ''
key.EMAIL           =   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
key.MOBILE          =   /^\d{10}$/
key.NumOnly         =   /^\d+$/
key.PASSWORD        =   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
key.OnlyAlbhabets   =   /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/
key.notSpecil       =   /^[a-zA-Z0-9]+$/
key.OnlyAlphSpecial =   /^[A-Za-z_@.#&+-]*$/
key.IPFS            =   ' '
key.limit = 50
key.NumDigitOnly = /[^0-9\.]/g
key.NumberOnly = /[^0-9]/g
if(EnvName === "demo") {
    key.FRONT_URL       =   'https://nftdemo.bimaticz.com/XDSEANFT'
    key.BACK_URL        =   'http://192.53.121.26:1208/v1/front'
    key.ADMIN_URL        =   'http://192.53.121.26:1208/v1/admin'
    key.IMG_URL         =   'http://192.53.121.26:1208'
    
    // // xdsea network
    // key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'
    // key.BLOCK_EXPLORER        = "https://rpc.apothem.network"
    // key.RPC_URL        = "https://apothemxdcpayrpc.blocksscan.io/"
    // key.CHAIN_ID        =   51
    // key.COIN_NAME        =   "XDC"Lis
    // key.TradeContract   =   '0xd29CA1888bAb7aB95A62A70761dfaAEEb192d874'
    // key.ERC721          =   '0xdb878ecbf722ea098dc85eb5750b05f765252b4d'
    // key.ERC1155         =   '0x037f3cdca193c203bc58923c841323916c4f8b0c'
    // key.erc20Address    =   '0xc9c7b7723961b43b73b8b2e39a6d17288ebabcb8'  //cake
   

 //-----> BSC chain Testnet

 key.RPC_URL         =   "https://data-seed-prebsc-1-s2.binance.org:8545"
 key.CHAIN_ID        =   97
 key.COIN_NAME        =   "XDC"
 key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'
 key.erc20Address    =   '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'
 key.TradeContract   =   '0xFec470a6159727C6765C27D601E3d53476aAc346'   // Bulk Mint Added
 key.ERC721          =   '0xe831eaD7180DAD1c3995002dd2fc366428D68D03'     
 key.ERC1155         =   '0x6eca68a45589b7caedac630c5cff6bb094750baf' 
 key.CHAIN_NAME        =   "Binance"
}
else if(EnvName === "stage") {
    
}
else if(EnvName === "production") {
    key.FRONT_URL       =   'https://www.xdsea.com'
    // key.FRONT_URL       = "https://nftdemo4.maticz.com/"
    key.BACK_URL        =   'https://api.xdsea.com/v1/front'
    key.ADMIN_URL        =   'https://api.xdsea.com/v1/admin'
    key.IMG_URL         =   'https://api.xdsea.com'
    // key.RPC_URL         =   "https://xdcpayrpc.blocksscan.io/"
    key.RPC_URL         =   "https://rpc.xdcrpc.com/"
    key.CHAIN_ID        =    50
    key.COIN_NAME        =   "XDC"
    key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'
    key.erc20Address    =   '0x8a3cc832bb6b255622e92dc9d4611f2a94d200da'
    key.TradeContract   =   '0xCDcE3724fBa95c29fca1c03F22e0fC77Da3C15c1'   
    key.ERC721          =   '0x51cd989e1163abf8262bfcd7eb782d6ce1c1074a'   
    key.ERC1155         =   '0x3e50c80f8a6a173a8059830d0cf0c9e5b04c43c0'
    key.CHAIN_NAME        =   "XDC" 
    key.RETROSTAKE_ADDRESS        =   "0x4101e8Ad5A9977dd23421F6404c6D0449be0e813"

}
else{

    key.FRONT_URL       =   'http://localhost:3000'
    key.BACK_URL        =   'http://localhost:3003/v1/front'
    key.ADMIN_URL        =   'http://localhost:3003/v1/admin'
    key.IMG_URL         =   'http://localhost:3003'

    ///----> Xdc Testnet

    
    // key.BLOCK_EXPLORER        = "https://rpc.apothem.network"
    // key.RPC_URL        = "https://apothemxdcpayrpc.blocksscan.io/"
    // key.CHAIN_ID        =   51
    // key.COIN_NAME        =   "XDC"
    // key.TradeContract   =   '0xd29CA1888bAb7aB95A62A70761dfaAEEb192d874'config.RPC_URL
    // key.ERC721          =   '0xdb878ecbf722ea098dc85eb5750b05f765252b4d'
    // key.ERC1155         =   '0x037f3cdca193c203bc58923c841323916c4f8b0c'
    // key.erc20Address    =   '0xc9c7b7723961b43b73b8b2e39a6d17288ebabcb8'  //cake

    

    //----> Avax

    // key.RPC_URL         =   "https://api.avax-test.network/ext/bc/C/rpc"
    // key.CHAIN_ID        =   43113

    //-----> BSC chain Testnet

//     key.RPC_URL         =   "https://data-seed-prebsc-1-s2.binance.org:8545"
//     key.CHAIN_ID        =    97
//     key.COIN_NAME        =   "BNB"
//      key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'
//  key.erc20Address    =   '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'
//  key.TradeContract   =   '0xFec470a6159727C6765C27D601E3d53476aAc346'   // Bulk Mint Added
//  key.ERC721          =   '0xe831eaD7180DAD1c3995002dd2fc366428D68D03'     
//  key.ERC1155         =   '0x6eca68a45589b7caedac630c5cff6bb094750baf' 
//  key.CHAIN_NAME        =   "Binance" 
key.RPC_URL         =   "https://rpc.xdcrpc.com/"
key.CHAIN_ID        =    50
key.COIN_NAME        =   "XDC"
key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'
key.erc20Address    =   '0x8a3cc832bb6b255622e92dc9d4611f2a94d200da'
key.TradeContract   =   '0xCDcE3724fBa95c29fca1c03F22e0fC77Da3C15c1'   
key.ERC721          =   '0x51cd989e1163abf8262bfcd7eb782d6ce1c1074a'   
key.ERC1155         =   '0x3e50c80f8a6a173a8059830d0cf0c9e5b04c43c0'
key.CHAIN_NAME        =   "XDC" 
key.RETROSTAKE_ADDRESS        =   "0x4101e8Ad5A9977dd23421F6404c6D0449be0e813"



}
export default key;
 